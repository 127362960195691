
function projectId (state) {
  return state.projectId;
}

function projectName (state) {
  return state.projectName;
}

function projectSchema (state) {
  return state.projectSchema;
}

export default { projectId, projectName, projectSchema };
