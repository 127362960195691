<template>
  <v-container fluid>
    <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="options"
    :loading="loading"
    >

      <template v-slot:item.pid="{item, value}">
        <a :href="`/projects/${item.pid}`">{{value}}</a>
      </template>

      <template v-slot:item.shots="{item}">
        {{ item.total ? (item.prime + item.other) : "" }}
      </template>

      <template v-slot:item.progress="{item}">
        {{
          item.total
            ? ((1 - (item.remaining / item.total))*100).toFixed(1)+"%"
            : ""
        }}
        <v-progress-linear v-if="item.total"
          height="2"
          :value="((1 - (item.remaining / item.total))*100)"
        />
      </template>


    </v-data-table>

  </v-container>
</template>

<style>
td p:last-of-type {
  margin-bottom: 0;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "ProjectList",

  data () {
    return {
      headers: [
        {
          value: "pid",
          text: "Project ID"
        },
        {
          value: "name",
          text: "Name"
        },
        {
          value: "lines",
          text: "Lines"
        },
        {
          value: "seq_final",
          text: "Sequences"
        },
        {
          value: "total",
          text: "Preplot points"
        },
        {
          value: "shots",
          text: "Shots acquired"
        },
        {
          value: "progress",
          text: "% Complete"
        },
      ],
      items: [],
      options: {},
    }
  },

  computed: {
    ...mapGetters(['loading', 'serverEvent'])
  },

  watch: {
    async serverEvent (event) {
      if (event.channel == "project" && event.payload?.schema == "public") {
        if (event.payload?.operation == "DELETE" || event.payload?.operation == "INSERT") {
          await this.load();
        }
      }
    }
  },

  methods: {
    async list () {
      this.items = await this.api(["/project"]) || [];
    },

    async summary (item) {
      const details = await this.api([`/project/${item.pid}/summary`]);
      if (details) {
        return Object.assign({}, details, item);
      }
    },

    async load () {
      await this.list();
      const promises = [];
      for (const key in this.items) {
        const item = this.items[key];
        const promise = this.summary(item)
        .then( expanded => {
          if (expanded) {
            this.$set(this.items, key, expanded);
          }
        });
        promises.push(promise);
      }
    },

    ...mapActions(["api"])
  },

  mounted () {
    this.load();
  }
}

</script>
