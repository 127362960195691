<template>
  <v-container fluid fill-height class="ma-0 pa-0">
    <v-row no-gutters align="stretch" class="fill-height">
      <v-col cols="12" v-if="projectFound">
        <!-- Show component here according to selected route -->
        <keep-alive>
          <router-view :key="$route.path"></router-view>
        </keep-alive>
      </v-col>
      <v-col cols="12" v-else>
        <v-card>
          <v-card-text>
            Project does not exist.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Project',
  components: {
  },
  data () {
    return {
    //
    }
  },

  computed: {

    projectFound () {
      return this.loading || this.projectId;
    },

    ...mapGetters(["loading", "projectId", "serverEvent"])
  },

  watch: {
    async serverEvent (event) {
      if (event.channel == "project" && event.payload?.operation == "DELETE" && event.payload?.schema == "public") {
        // Project potentially deleted
        await this.getProject(this.$route.params.project);
      }
    }
  },

  methods: {
    ...mapActions(["getProject"])
  },

  async mounted () {
    await this.getProject(this.$route.params.project);
  }

}
</script>
